@import "ThemeColors.scss";
.break-th-text {
  max-width: 250px;
  white-space: break-spaces !important;
}

.modal-title-break {
  word-break: break-all;
}
.dropdown-menu-end {
  z-index: 999 !important;
}
// .btn-secondary,
// .btn-secondary:hover,
// .btn-secondary:active,
// .btn-secondary:visited,
// .btn-secondary:focus {
//   background-color: #8064a2 !important;
//   border-color: #8064a2 !important;
// }

[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: var(--toastify-color-error) !important;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: var(--toastify-color-success) !important;
}

.filepond--drop-label {
  background-color: white !important;
  height: auto !important;
}

.filepond--list-scroller {
  background-color: white !important;
}

.chat {
  height: 70vh;
  overflow: auto;

  .my {
    color: white;
    background: var(--vz-vertical-menu-bg-dark);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .notMy {
    background: var(--vz-topbar-search-bg);
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: block;
  }
}

.w-translate {
  min-width: 180px;
}

.font-date {
  font-size: 10px;
}

.custom_zindex {
  z-index: 900;
}

.custom_zindex_up {
  z-index: 993 !important;
}

h1.ck-placeholder {
  display: none !important;
}

.filepond--panel-root {
  background-color: transparent !important;
  border: none !important;
}

.title-variation {
  font-weight: 600;
  margin-bottom: 0;
  font-size: small;
}

html[data-layout-mode="dark"] {
  .react-select-field {
    color: white;

    div {
      border-color: var(--vz-input-border);
      background: var(--vz-input-bg);
      color: white;

      [class*="singleValue"] {
        color: white;
        z-index: 1;
      }
    }

    ::placeholder {
      color: green !important;
      /* Change the color of the placeholder text in dark mode here */
    }
  }

  .MuiFormControl-root {
    border-color: var(--vz-input-border);
    background: var(--vz-input-bg);

    .MuiSelect-select {
      color: var(--vz-body-color);

      span {
        color: var(--vz-body-color);
      }
    }

    svg {
      color: white;
    }
  }

  .MuiPaper-root {
    background: var(--vz-input-bg);

    li {
      color: var(--vz-body-color);
    }
  }
}

html[data-layout-mode="dark"] {
  .pagination-field {
    background: var(--vz-input-bg);
  }
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  // color: green;
}

.css-14el2xx-placeholder {
  z-index: 0;
}

.date-picker {
  min-height: 38px;
  width: 100%;
  border: 0.1px solid var(--vz-input-border) !important;
  border-radius: 5px;
}

/////
html[data-layout-mode="dark"] {
  .date-picker {
    border-color: var(--vz-input-border);
    background: var(--vz-input-bg);
    color: white;
  }

  ::placeholder {
    color: white !important;
    /* Change the color of the placeholder text in dark mode here */
  }
}

///

// .datepicker-container input::placeholder {
//   color: hsl(0, 0%, 50%);
//   padding-left: 8px;
//   box-sizing: border-box;
// }
.datepicker-container input {
  color: hsl(0, 0%, 20%);
  padding-left: 8px;
  box-sizing: border-box;
}

.min-input-height {
  min-height: 38px;
}

.nav-fill .nav-item.custom-basis {
  text-align: center;
  flex: 0 1 auto !important;
  flex-basis: 25% !important;
}

.translation-height {
  height: 46px;
}

.custom_pointer {
  cursor: pointer;
}

.main-height {
  height: 100vh;
}

.custom-bold {
  font-weight: 900;
}
.ckeditor-custom {
  max-height: 300px;
}
.ck-restricted-editing_mode_standard {
  max-height: 600px;
}
.h-listbox {
  min-height: 350px;
}
#chatinput-form {
  min-height: 300px;
}
.chat-input {
  min-height: 100px !important;
}
.border-mapping {
  border: 1px solid #405189 !important;
}
.figure-img {
  width: 150px;
  max-height: 150px;
  margin-left: auto;
}
.flag-field {
  height: 47px;
  width: 51px;
}
.w-400 {
  width: 400px;
}
.w-400 .css-1ko62if-control {
  width: 400px;
}
.py-doc {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.fw-500 {
  font-weight: 500;
}
//select style
.css-g1d714-ValueContainer {
  height: auto !important;
  min-height: 30px !important;
}
.css-152iwgl-control {
  height: auto !important;
  min-height: 30px !important;
}
.css-7pnjy9-control {
  height: auto !important;
  min-height: 30px !important;
}
.mapping-import {
  textarea {
    resize: none;
  }
  .css-319lph-ValueContainer {
    padding: 2px !important;
  }
  // .css-6j8wv5-Input {
  //   display: none;
  // }
  .css-tlfecz-indicatorContainer {
    padding: 2px !important;
  }
  .css-1fby9lg-control {
    min-height: 20px !important;
  }
  .css-1gtu0rj-indicatorContainer {
    padding: 2px !important;
  }
  .css-1ko62if-control {
    min-height: 30px !important;
  }
  .css-319lph-ValueContainer {
    height: 30px !important;
  }
  .css-qc6sy-singleValue {
    min-height: 30px !important;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
  }
  .css-14el2xx-placeholder {
    min-height: 30px !important;
  }
}
// .table-import {
//   :not(caption) > * > * {
//     padding: 0 0 !important;
//   }
// }
// .table-import {
//   tr {
//     max-height: 50px !important;
//   }
// }

table tr {
  height: 10px;
}
.sticky-header {
  position: sticky;
  top: 70px;
  z-index: 3;
}
.custom-base-workflow {
  min-width: 150px;
}
.css-i0se6z-control {
  z-index: 998 !important;
}
.css-26l3qy-menu {
  z-index: 990 !important;
}
.custom-fix {
  .css-26l3qy-menu {
    z-index: 999 !important;
  }
}
.carousel-inner {
  text-align: center;
}
.w-250 {
  width: 250px;
}
.sticky-header-offcanvas {
  position: sticky !important;
  top: 0;
  z-index: 3;
  background-color: #ffffff;
}
.w-140 {
  width: 140px !important;
}
.w-210 {
  width: 210px !important;
}
.modal-backdrop.show {
  opacity: 0.1 !important;
}
.shorcut-workflow :hover {
  color: #0ab39c !important;
}
.chart-container {
  position: relative;
  height: 70px;
  width: 110px;
  right: -25px;
}
.css-26l3qy-menu {
  z-index: 9999 !important;
}
[data-layout-mode="dark"] {
  .toolbar-pdf {
    background-color: #31373c !important;
    color: white;
  }
  .sticky-form {
    background-color: #31373c !important;
  }
  .filepond--root :not(text) {
    font-size: inherit;
    background-color: rgb(33, 37, 41) !important;
    color: #ffffff;
  }
  .dark-text-area {
    background-color: #292e32;
    border: 1px solid rgb(26, 29, 33);
    color: #e7e8e9;
  }
}
.chart-fixed {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
}
