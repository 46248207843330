//
// filepond
//

.filepond--root {
  margin-bottom: 0;
  max-height: 200px;
  &[data-style-panel-layout~="circle"] {
    .filepond--drop-label label {
      font-size: 14px;
    }
  }
}

.filepond--panel-root {
  border: 2px dashed $border-color;
  background: $card-bg;
}

.filepond--drop-label {
  color: var(--#{$variable-prefix}body-color);
  label {
    font-weight: $font-weight-medium;
  }
}

.filepond--credits {
  display: none;
}

.filepond--item-panel {
  background-color: $primary !important;
}
