:root {
  --vz-danger-rgb {
    color: #f63d68 !important;
  }
  .btn-primary {
    color: #fff;
    background-color: #2d31a6;
    border-color: #2d31a6;
  }
  .btn-outline-primary {
    color: #2d31a6;
    background-color: #fff;
    border-color: #2d31a6;
  }
  .btn-outline-primary:hover {
    background-color: #2d31a6;
  }
  .btn-primary:focus {
    background-color: #2d31a6;
    border-color: #2d31a6;
  }
  .btn-primary:hover {
    background-color: #444ce7;
  }

  .btn-primary:disabled {
    background-color: #8098f9;
    border-color: #8098f9;
  }
  .text-primary {
    color: #2d31a6;
  }
  .btn-success {
    color: #fff;
    background-color: #22ccee;
    border-color: #22ccee;
  }
  .btn-outline-success {
    color: #22ccee;
    background-color: #fff;
    border-color: #22ccee;
  }
  .btn-outline-success:hover {
    background-color: #22ccee;
  }
  .btn-success:focus {
    background-color: #22ccee;
    border-color: #22ccee;
  }
  .btn-success:hover {
    background-color: #67e3f9;
    border-color: #67e3f9;
  }
  .btn-success:disabled {
    background-color: #a5f0fc;
    border-color: #a5f0fc;
  }
  .text-success {
    color: #22ccee;
  }
  .btn-secondary {
    color: #fff;
    background-color: #6172f3;
    border-color: #6172f3;
  }
  .btn-outline-secondary {
    color: #6172f3;
    background-color: #fff;
    border-color: #6172f3;
  }
  .btn-outline-secondary:hover {
    background-color: #6172f3;
  }
  .btn-secondary:focus {
    background-color: #6172f3;
    border-color: #6172f3;
  }
  .btn-secondary:hover {
    background-color: #8098f9;
    border-color: #8098f9;
  }
  .btn-secondary:disabled {
    background-color: #a4bcfd;
    border-color: #a4bcfd;
  }
  .text-secondary {
    color: #6172f3;
  }
  .btn-warning {
    color: #fff;
    background-color: #fac515;
    border-color: #fac515;
  }
  .btn-outline-warning {
    color: #fac515;
    background-color: #fff;
    border-color: #fac515;
  }
  .btn-outline-warning:hover {
    background-color: #fac515;
    color: #ffffff;
  }
  .btn-warning:focus {
    background-color: #fac515;
    border-color: #fac515;
    color: #fff;
  }
  .btn-warning:hover {
    background-color: #fde272;
    border-color: #fde272;
    color: #ffffff;
  }
  .btn-warning:disabled {
    background-color: #feee95;
    border-color: #feee95;
  }
  .text-warning {
    color: #fac515;
  }
  .btn-danger {
    color: #fff;
    background-color: #f63d68;
    border-color: #f63d68;
  }
  .btn-outline-danger {
    color: #f63d68;
    background-color: #fff;
    border-color: #f63d68;
  }
  .btn-outline-danger:hover {
    background-color: #f63d68;
  }
  .btn-danger:focus {
    background-color: #f63d68;
    border-color: #f63d68;
  }
  .btn-danger:hover {
    background-color: #fd6f8e;
    border-color: #fd6f8e;
  }
  .btn-danger:disabled {
    background-color: #fea3b4;
    border-color: #fea3b4;
  }
  --vz-danger-rgb {
    color: #f63d68 !important;
  }
  .text-danger {
    color: #f63d68;
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////
[data-layout-mode="dark"] {
  .btn-primary {
    color: #fff;
    background-color: #2d3282;
    border-color: #2d3282;
  }
  .btn-outline-primary {
    color: #2d3282;
    background-color: rgb(33, 37, 41);
    border-color: #2d3282;
  }
  .btn-outline-primary:hover {
    background-color: #2d3282;
  }
  .btn-primary:focus {
    background-color: #2d3282;
    border-color: #2d3282;
  }
  .btn-primary:hover {
    background-color: #4147a0;
    border-color: #4147a0;
  }
  .btn-primary:disabled {
    background-color: #a7b1d8;
    border-color: #a7b1d8;
  }
  .text-primary {
    color: #2d3282;
  }
  .btn-success {
    color: #fff;
    background-color: #06aed4;
    border-color: #06aed4;
  }
  .btn-outline-success {
    color: #06aed4;
    background-color: rgb(33, 37, 41);
    border-color: #06aed4;
  }
  .btn-outline-success:hover {
    background-color: #06aed4;
  }
  .btn-success:focus {
    background-color: #06aed4;
    border-color: #06aed4;
  }
  .btn-success:hover {
    background-color: #22ccee;
    border-color: #22ccee;
  }
  .btn-success:disabled {
    background-color: #a3c9cf;
    border-color: #a3c9cf;
  }
  .text-success {
    color: #06aed4;
  }
  .btn-secondary {
    color: #fff;
    background-color: #6172f3;
    border-color: #6172f3;
  }
  .btn-outline-secondary {
    color: #6172f3;
    background-color: rgb(33, 37, 41);
    border-color: #6172f3;
  }
  .btn-outline-secondary:hover {
    background-color: #6172f3;
  }
  .btn-secondary:focus {
    background-color: #6172f3;
    border-color: #6172f3;
  }
  .btn-secondary:hover {
    background-color: #8098f9;
    border-color: #8098f9;
  }
  .btn-secondary:disabled {
    background-color: #a6b2d0;
    border-color: #a6b2d0;
  }
  .text-secondary {
    color: #6172f3;
  }
  .btn-warning {
    color: #fff;
    background-color: #fac515;
    border-color: #fac515;
  }
  .btn-outline-warning {
    color: #fac515;
    background-color: rgb(33, 37, 41);
    border-color: #fac515;
  }
  .btn-outline-warning:hover {
    background-color: #fac515;
  }
  .btn-warning:focus {
    background-color: #fac515;
    border-color: #fac515;
    color: #fff;
  }
  .btn-warning:hover {
    background-color: #fde272;
    border-color: #fde272;
    color: #ffffff;
  }
  .btn-warning:disabled {
    background-color: #d1cbaa;
    border-color: #d1cbaa;
  }
  .text-warning {
    color: #fac515;
  }
  .btn-danger {
    color: #fff;
    background-color: #f97066;
    border-color: #f97066;
  }
  .btn-outline-danger {
    color: #f97066;
    background-color: rgb(33, 37, 41);
    border-color: #f97066;
  }
  .btn-outline-danger:hover {
    background-color: #f97066;
  }
  .btn-danger:focus {
    background-color: #f97066;
    border-color: #f97066;
  }
  .btn-danger:hover {
    background-color: #ff8a81;
    border-color: #ff8a81;
  }
  .btn-danger:disabled {
    background-color: #d0a09c;
    border-color: #d0a09c;
  }
  .text-danger {
    color: #f97066;
  }
}

[data-layout="vertical"] .navbar-menu {
  background-color: #2d264c !important;
}
[data-layout-mode="dark"] {
  .navbar-menu {
    background-color: #212529 !important;
  }
}
// [data-layout-mode="light"] {
//   $blue: #6172f3;
//   $indigo: #2d31a6;
//   $purple: #6559cc;
//   $pink: #f672a7;
//   $red: #f63d68 !important;
//   $orange: #f1963b;
//   $yellow: #fac515;
//   $green: #06aed4;
//   $teal: #02a8b5;
//   $cyan: #6d84f5;
// }

// }
// [data-layout="vertical"][data-sidebar="dark"] .navbar-nav .nav-link {
//   color: #817d94 !important;
// }
// [data-layout="vertical"][data-sidebar="dark"] .menu-title {
//   color: #817d94 !important;
// }
// [data-layout="vertical"][data-sidebar="dark"]
//   .navbar-nav
//   > .nav-item
//   .nav-link.active {
//   color: #ffffff !important;
// }
// [data-layout="vertical"][data-sidebar="dark"]
//   .navbar-nav
//   > .nav-item
//   .nav-link:hover {
//   color: #ffffff !important;
// }
// .page-content {
//   background-color: #f4f4f6 !important;
// }
// [data-layout-mode="dark"] {
//   .page-content {
//     background-color: #1a1d21 !important;
//   }
// }
// .table-light {
//   --vz-table-bg: #eae9ed !important;
// }
// .btn-warning {
//   color: #ffffff !important;
// }
// [data-layout-mode="dark"] {
//   --vz-header-bg: #2d264c !important;
//   --vz-card-bg: #2d264c !important;
//   --vz-card-cap-bg: #2d264c !important;
//   .form-control {
//     background-color: #2d264c !important;
//   }
//   --vz-light: #2d264c !important;
//   --vz-input-bg: #2d264c !important;
//   --vz-topbar-user-bg: #2d264c !important;
// }

// ===================================================================================
.pagination {
  .active {
    background-color: #2d31a6 !important;
    color: #fff !important;
  }
}
[data-layout-mode="dark"] {
  .pagination .pageItem:hover {
    background-color: rgb(42, 47, 52) !important;
  }
  .pagination {
    .active {
      background-color: rgb(26, 29, 33) !important;
    }
  }
}
